<template>

  <AuditSystemGroupBase
      title="群聊审核"
      :auditTypes=auditTypes
      :handle-moles=handleMoles
      defaultHandleMole="IGNORE">
  </AuditSystemGroupBase>

</template>

<script>
import AuditSystemGroupBase from "./audit-system-group-base"

export default {

  data() {
    return {
      auditTypes: [4],
      handleMoles: [
        {
          handleMode: 'IGNORE',
          desc: '忽略'
        },
        {
          handleMode: 'OFFLINE_PLANET',
          desc: '下星球页'
        },
        {
          handleMode: 'MUTE_GROUP_1',
          desc: '禁言1天',
          config: {
            muteSecond: 60 * 60 * 24
          }
        },
        {
          handleMode: 'MUTE_GROUP_3',
          desc: '禁言3天',
          config: {
            muteSecond: 60 * 60 * 24 * 3
          }
        },
        {
          handleMode: 'MUTE_GROUP_7',
          desc: '禁言7天',
          config: {
            muteSecond: 60 * 60 * 24 * 7
          }
        },
        {
          handleMode: 'DESTROY_GROUP',
          desc: '封禁群'
        }
      ]
    }

  },
  components: {
    AuditSystemGroupBase
  },
  methods: {}
}
</script>

<style scoped lang="scss">
</style>

